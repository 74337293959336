 
section {
    display: block;
    position: relative;
    z-index: 0;
}

.stamp {
    display: block;
    position: absolute;
    z-index: 1;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.btn-menu-header {
    display: block;
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;
}

.text-header {
    display: block;
    position: absolute;
    width: 100%;
    right: 50px;
    bottom: 150px;
    text-align: right;
}

.h1 {
    text-shadow: 1px 10px 0px rgba(0,0,0,0.6);
    font-family: Fibre, 'Boogaloo', cursive;
    font-size: 90px;
    line-height: 79px;
}

.h2 {
    font-family: Fibre, 'Boogaloo', cursive;
    font-size: 80px;
}

.h3 {
    font-family: Fibre, 'Boogaloo', cursive;


    font-size: 4rem;
    line-height: 79px;
}

.h4 {
    font-family: Fibre, 'Boogaloo', cursive;
    font-size: 45px;
}

.h5 {
    font-family: Fibre, 'Boogaloo', cursive;
    font-size: 30px;
    line-height: 25px;
}

.h6 {
    font-family: Fibre, 'Boogaloo', cursive;
    font-size: 23px;
}

.container-text-news {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    background: url('./../../assets/img/black.png') no-repeat top center;
    opacity: 0.7;
    background-size: cover;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    height: 130px;
    margin-top: -130px;
    text-align: center;
}

.text-news {
    color: white;
    font-family: Fibre, 'Boogaloo', cursive;
    font-size: 45px;
}
/* header */ 


.logo-header {
    display: block;
    position: relative;
    opacity: 0.9;
    width: 563px;
}

.images-portada {
    display: block;
    position: relative;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.header {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
}

.btn-red {
    display: inline-block;
    position: relative;
    box-shadow: 1px 9px 10px rgba(0,0,0,0.5);
    font-family: Fibre, 'Boogaloo', cursive;
    color: #fff;
    font-size: 35px;
    background: var(--red);
    padding: 10px 30px;
    padding-top: 0px;
    border-radius: 30px;
    transition-duration: 300ms;
    text-decoration: none;
    backdrop-filter: blur(13px);
    z-index: 1;
}

.btn-yellow {
    display: inline-block;
    position: relative;
    box-shadow: 1px 9px 10px rgba(0,0,0,0.5);
    font-family: Fibre, sans-serif;
    color: #fff;
    font-size: 30px;
    background: var(--yellow);
    padding: 7px 30px;
    padding-top: 0px;
    border-radius: 30px;
    transition-duration: 300ms;
    text-decoration: none;
    backdrop-filter: blur(13px);
    z-index: 1;
}

.rotate {
    transform: rotate(-10deg);
}

.btn-red:hover {
    transition-duration: 300ms;
    transform: scale(1.15) ;
    color: #fff;
    z-index: 2;
}

.btn-yellow:hover {
    transition-duration: 300ms;
    transform: scale(1.15) ;
    color: #fff;

}

/* end */ 

/* publicity */ 


.modal-img {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.9);
    z-index: 10000;
}

/* end */

/* map */ 

.pin {
    display: block;
    position: relative;
    margin: auto auto;
    font-size: 60px;
    text-align: center;
    animation-duration: 0.7s;
    animation-name: saltar;
    animation-iteration-count: infinite;
    animation-direction:alternate;
    top: 39%;
    left: -4px;
    color: var(--red);
    opacity: 0.6
}
/* end */

/* news */ 

.text-danger {
    color: var(--red) !important;
}

.bg-danger {
    background-color: var(--red) !important;
}

.emoji {
    display: inline-block;
    position: relative;
    background: url('../../assets/img/emoji.png') center no-repeat;
    background-size: cover;
    width: 70px;
    height: 70px;
    animation-duration: 1.5s;
    animation-name: saltar;
    animation-iteration-count: infinite;
    animation-direction:alternate;
}

.saltar {
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-name: saltarb;
    animation-iteration-count: infinite;
    animation-direction:alternate;
}

/* end */ 

/* combos */

.img-fluid {
    display: inline-block;
    position: relative;
    max-width: 100%;
    max-height: 100px;
    margin: 0 0;
}

.card-img-top {
    display: inline-block;
    position: relative;
    max-width: 100%;
    max-height: 250px;
    margin: 0 0;
}

.img-combos {
    display: inline-block;
    position: absolute;
    right: -10px;
    bottom: 0px;
    width: 400px;
    height: auto;
}

/* end */

/* Redes */

.redes-icon {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;
}

/* end */

.zoom {
    transition-duration: 300ms;
}

.zoom:hover {
    transition-duration: 300ms;
    transform: scale(1.03);
}

@media only screen and (max-width: 700px) {   
 
    section {
        min-height: 50vh;
    }

    .btn-menu-header {
        bottom: 60px;
    }
    
    .h1 {
        text-shadow: 1px 6px 0px rgba(0,0,0,0.6);
        font-size: 50px;
        line-height: 50px;
    }
    
    .h2 {   
        font-size: 45px;
        line-height: 39px;
    }
    
    .h3 {
        font-family: Fibre, 'Boogaloo', cursive;
        font-size: 2rem;
        line-height: 35px;
    }
    
    .h4 {
        font-size:33px;
    }

    .h5 {
        font-size: 30px;
        line-height: 25px;
    }

    .h6 {
        font-size: 20px;
    }

    .h7 {
        font-family: Fibre, 'Boogaloo', cursive;
        font-size: 17px;
    }

    .container-text-news {
        border-radius: 30px;
        height: 60px;
        margin-top: -60px;
        text-align: center;
    }

    .text-news {
        font-size: 21px;
    }
    
    /* header */
    
    .logo-header {
        width: 300px;
    }

    .header {
        height: 100vh;
        display: flex;
        align-items: center;
        background-color: black;
    }
    
    .btn-red {
        font-size: 30px;
        padding-top: 9px;
        padding-bottom: 0px;
    }
    
    .text-header {
        display: block;
        position: absolute;
        width: 100%;
        right: auto ;
        left: 10px;
        bottom: 150px;
        text-align: left;
    }
    .btn-yellow {
        display: inline-block;
        position: relative;
        box-shadow: 1px 5px 10px rgba(0,0,0,0.5);
        font-family: Fibre, sans-serif;
        color: #fff;
        font-size: 20px;
        background: var(--yellow);
        padding: 7px 20px;
        padding-top: 0px;
        border-radius: 30px;
        transition-duration: 300ms;
        text-decoration: none;
        backdrop-filter: blur(13px);
        z-index: 1;
    }
    
    .rotate {
        transform: rotate(-10deg);
    }
    
    .btn-yellow:hover {
        transition-duration: 300ms;
        transform: scale(1.15) ;
        color: #fff;
    
    }
    
    /* end */ 
    
    
    /* news */ 
    
    .text-danger {
        color: var(--red) !important;
    }
    
    .bg-danger {
        background-color: var(--red) !important;
    }
    
    .emoji {
        display: inline-block;
        position: relative;
        background: url('../../assets/img/emoji.png') center no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        animation-duration: 1.5s;
        animation-name: saltar;
        animation-iteration-count: infinite;
        animation-direction:alternate;
    }
    
    /* end */ 

    /* combos */

    /* end */

    /* publicity */ 

        .img-publicity-carousel {
            width: auto;
            height: auto;
            max-width: 90vw;
            max-height: 100vh;
            border-radius: 20px;
        }

    /* end */
  
}

@keyframes saltar {
    from {
      transform: scale(1)
    }
  
    to {
        transform: scale(1.3)
    }
  }

@keyframes saltarb {
    from {
      transform: scale(1)
    }
  
    to {
        transform: scale(1.05)
    }
  }