
.images-menu {
    display: block;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0.3 !important;
    z-index: -1;
}

.btn-add {
    display: inline-block;
    position: absolute;
    text-align: center;
    right: 15px;
    top: 55px;
    font-size: 35px;
    z-index: 2;
    transition-duration: 300ms;
}

.btn-add:hover {
    transition-duration: 300ms;
    transform: scale(1.1);
}
.cart {
    font-size: 60px;
    line-height: 30px;
    color: black
}

.font {
    font: menu;
    font-size: 13px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 700px) {   
    .cart {
        display: inline-block;
        position: relative;
        font-size: 37px;
        line-height: 25px;
        color: black;
    }
    .font {
        font: menu;
        font-size: 12px;
    }
}