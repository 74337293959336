/* admin  nav */

.imguser {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0 0;
    margin-left: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
}

.logoadmin {
    display: inline-block;
    position: relative;
    width: 13vw;
    height: auto;
    margin-bottom: -190px;
}

/* end */


/* nav */

.navbar-client {
    font-family: Fibre, 'Boogaloo', cursive;
    
}

.text-nav {
    font-size: 35px;
    color:white;
    margin: 10px 25px;
    transition-duration: 100ms;
    text-decoration: none;
}

.text-nav:hover {
    color: #fff;
    transition-duration: 100ms;
}

.navbar-client .active {
    color: #fff !important;
    transition-duration: 100ms;
}


/* end */

@media only screen and (max-width: 700px) {  
    
    /* nav */


    .text-nav {
        display: block;
        position: relative;
        font-size: 23px;
        color:white;
        margin: 7px 0px;
        text-align: right;
        transition-duration: 100ms;
        text-decoration: none;
    }

    .text-nav:hover {
        color: #fff;
        transition-duration: 100ms;
    }

    .navbar-client .active {
        color: #fff !important;
        transition-duration: 100ms;
    }

    .navbar-toggler {
        display: block;
        margin-left: 68vw;
        border: none;
        font-size: 30px;
    }


    /* end */  
 }