@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Boogaloo&display=swap');
@font-face {
    font-family: 'Allison';
    src: url('./assets/fonts/Allison.ttf') format('truetype');
    font-style: light;
    font-weight: normal;
}

@font-face {
    font-family: 'Fibre';
    src: url('./assets/fonts/Fibre.otf') format('truetype');
    font-style: light;
    font-weight: normal;
}

:root {
    --red: #ce1a23;
    --red-gradient:  linear-gradient(90deg, rgba(206,26,35,1) 36%, rgba(206,26,35,0.35057773109243695) 100%);
    --yellow: #f4b334
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    overflow-x: hidden; 
}

.rounded {
    border-radius: 30px !important;
}

.rounded-top {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.modalProduct {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    z-index: 1040;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(15px);
}

.menu-movil {
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    z-index: 1;
}

.bg {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: url('./assets/img/bg.jpg') center center;
    background-size: cover;
    opacity: 0.16;
    top:0;
    z-index: -2;
    filter: invert(1);
}

.bg-blur {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
}

.title {
    font-family: 'Bebas Neue', cursive;
}

.cart-container {
    display: block;
    position: fixed;
    right: 100px;
    top: 25px;
}

.previous {
    display: block;
    position: fixed;
    top: 0;
    left: 100px;
    margin-top: -17px;
}

.previous-right {
    display: block;
    position: fixed;
    top: 0;
    right: 100px;
    margin-top: -17px;
}

.options {
    height: 100vh;
    display: flex;
    top: 0;
    align-items: center;
    position: fixed
}

.invert {
    filter: invert(0.9);
}

.pt-6 {
    padding-top: 90px;
}

.product {
    width: auto;
    max-height: 300px;
}

.list-group-item {
    color: rgba(0, 0, 0, 0.45);
    transition-duration: 200ms;
    border: none;
    background: transparent !important;
}

.list-group-item:hover, .list-group-item:focus {
    color: black;
    background: transparent;
    border: none;

}
.active {
    color: black !important;
    background: transparent !important;
    border-color: #ddd !important;
    box-sizing: 1px !important;
}

input, textarea, select{
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    margin: auto auto;
    border-radius: 0  !important;
    padding: 7px 10px;
    background: transparent;
    font-weight: bold !important;
}

.input-group-text {
    border-right: 1px solid transparent;
    border-radius: 0;
}

.docs {
    transition-duration: 500ms
}

.icon-delete {
    display: inline-block;
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    padding: 2px;
    margin-left:-20px
}

.login {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
    align-items: center;
}

@media only screen and (max-width: 700px) {   
    .cart-container {
        display: block;
        position: fixed;
        right: 22px;
        top: 15px;
    }
    
    .previous {
        left: 10px;
        margin-top: 0
    }
    
    .previous-right {
        right: 10px;
        margin-top: 0
    }
}
